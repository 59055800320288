<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div class="search-container-fn-input">
        <label>药品信息：</label>
        <el-input
          style="width: 200px"
          size="small"
          placeholder="药品名称/药品编号"
          prefix-icon="el-icon-search"
          v-model="searchParams.keywords"
          @change="getList('restPage')"
        >
        </el-input>
      </div>
      <div class="search-container-fn-input">
        <label>药品状态：</label>
        <el-select
          style="width: 200px"
          v-model="searchParams.enable_status"
          clearable
          placeholder="请选择"
          size="small"
          @change="loadList('restPage')"
        >
          <el-option
            v-for="item in [
              {
                value: 1,
                label: '启用',
              },
              {
                value: 2,
                label: '禁用',
              },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-container-fn-input">
        <label>是否处方药：</label>
        <el-select
          style="width: 200px"
          v-model="searchParams.pres_not"
          clearable
          placeholder="请选择"
          size="small"
          @change="loadList('restPage')"
        >
          <el-option
            v-for="item in isOrNotOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="
              currDrugId = multipleSelectionPres[0].drug_id;
              getDetail(multipleSelectionPres[0].drug_id, 'edit');
              getPharmacysNew(multipleSelectionPres[0].drug_id, 'edit');
            "
            v-if="multipleSelectionPres.length == 1"
            >编辑
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('delete') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="delDrug(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('add') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
            @click="addDrug"
            >新增
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currDrugId = scope.row.drug_id;
                getDetail(scope.row.drug_id, 'view');
                getPharmacysNew(scope.row.drug_id, 'view');
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              @change="changeStatus($event, scope.row)"
              v-model="scope.row.enable_status"
              class="switch"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#f5f5f5"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              @click="under(scope.row.overview_url, 'pic' + scope.$index)"
              v-if="scope.row.overview_url.length"
            >
              <a style="color:#2362FB" @click="img">查看图片</a>
              <el-image
                :ref="'pic' + scope.$index"
                style="width: 0; height: 0;"
                :z-index="zIndex"
                :src="scope.row.overview_url"
                :preview-src-list="[scope.row.overview_url]"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="column.column_prop == 'pres_not'">
              <span v-if="scope.row.pres_not">是</span>
              <span v-if="!scope.row.pres_not">否</span>
            </div>
            <div v-else-if="column.column_prop == 'is_black'">
              <span v-if="scope.row.is_black">是</span>
              <span v-if="!scope.row.is_black">否</span>
            </div>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="searchParams.created_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.created_at)
                "
              >
              </el-date-picker>
              <el-select
                v-if="'is_black' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.is_black)
                "
                v-model="searchParams.is_black"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in isOrNotOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-if="'drug_category_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.drug_category_name)
                "
                v-model="searchParams.cate_id"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in drugCategoryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
      :append-to-body="true"
      size="76%"
      :visible.sync="dialogDrugVisible"
      direction="rtl"
      :showClose="false"
    >
      <wDetails
        :info="info"
        :type="openType"
        :editInfo="editInfo"
        :pharmacys="pharmacys"
        :types="openType"
        :id="currDrugId"
        @editSuccess="editSuccess"
      >
      </wDetails>
    </el-drawer>
    <el-dialog
      title="药品导入"
      :visible.sync="dialogVisible4"
      width="770px"
      v-if="dialogVisible4"
    >
      <div class="bbox uploadExcel xbetween ycenter">
        <a href="https://pic.gegejk.com/drug_import_WEST.xlsx" target="_blank"
          >下载Excel模板</a
        >
        <div class="fileName">
          {{ uploadFileName }}
        </div>
        <div>
          <el-upload
            class="avatar-uploader"
            :auto-upload="true"
            accept=".xlsx"
            :show-file-list="false"
            :on-success="uploadExcelSuccess"
            :on-change="beforeAvatarUpload"
            :http-request="exportFiles"
          >
            <el-button size="small" class="uploadBtn">选择文件</el-button>
          </el-upload>
        </div>
      </div>
      <div class="text-gray" style="font-size:14px; margin-top: 10px;">
        提醒：请按照后台提供的格式上传表格进行导入。
      </div>
      <span slot="footer" class="dialog-footer">
        <!--          <el-button size="small" @click="dialogVisible4=false">取消</el-button>-->
        <el-button type="primary" size="small" @click="dialogVisible4 = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import { getDrugList, delDrug, enableDrug, westDrugsImport } from "@/api/drugs";
import {
  getDrugListNew,
  getPharmacysNew,
  getDrug,
  putStatus,
} from "@/api/drugs2.0";
import wDetails from "./components/wDetails2.0";
import { mapState } from "vuex";
import config from "@/utils/config";
import { getQNToken } from "@/api/basic";
import { Message } from "element-ui";
export default {
  name: "wMedicine",
  components: {
    Pagination,
    wDetails,
    editTableHead,
  },
  data() {
    return {
      table_type:'drugsList',
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "is_black",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
        {
          name: "drug_category_name",
          isSearch: false,
        }
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      info: {},
      editInfo: {},
      pharmacys: [],
      clearFilter: false,
      selected: false,
      showPop: false,
      dialogDrugVisible: false,
      openType: "",
      currDrugId: null,
      searchParams: {
        orderTime: [],
      },
      options: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      drugCategoryOptions: [
        // { label: '中药颗粒', value: 1 },
        {
          label: "西药",
          value: 2,
        },
        {
          label: "中成药",
          value: 3,
        },
        // { label: '中药饮片', value: 4 },
      ],
      isOrNotOptions: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      tableData: [],

      dialogVisible4: false,
      actionPath: "http://upload.qiniup.com",
      qiniuData: {
        token: "",
        key: "",
      },
      uploadFile: "",
      uploadFileName: "",
      operateList: [],
      flag: false,
      zIndex: 999,
    };
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
    this.getToken();
    setTimeout(() => {
      console.log(this.operateList, 23333, "operateList");
    }, 5000);
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });

    // 药品分类
    this.$store.dispatch("dictionary/getDicData", [
      {
        stateName: "drugSort",
        api: "/admin/drugManage/getDrugCateSelect",
      },
    ]);
  },
  computed: {
    dialogTitle() {
      return (
        {
          add: "添加药品",
          edit: "编辑药品",
          view: "查看药品",
        }[this.openType] || "添加"
      );
    },
    ...mapState({
      drugSort: (state) => state.dictionary.drugSort,
      table_options: (state) => state.user.table_options,
    }),
  },
  methods: {
    handleImg(row) {
      // 查看放大图片
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "drugsList",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.drugsList;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    changeStatus(e, row) {
      // let params = {
      // 	id: row.drug_id,
      // 	enable_status: e
      // }
      putStatus(row.drug_id, e)
        .then((res) => {
          if (res.code == 200) {
            var msg = e == 1 ? "已启用" : "已禁用";
            return this.$message.success(msg);
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addDrug() {
      this.$router.push({
        path: "/drugsManage/addDrug",
      });
    },
    getPharmacysNew(id) {
      let params = {};
      params.id = id;
      this.fullscreenLoading = true;
      getPharmacysNew(params)
        .then((response) => {
          let data = response;
          console.log(data);
          if (data.code == 200) {
            data.data.list.forEach((item) => {
              item.status == "2"
                ? (item.status_name = "上架")
                : (item.status_name = "待上架");
            });

            this.pharmacys = data.data.list;
            this.dialogDrugVisible = true;
          } else {
            Message({
              message: data.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
          this.fullscreenLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoading = false;
        });
    },
    getDetail(id, type) {
      let params = {};
      params.id = id;
      this.fullscreenLoading = true;
      getDrugListNew(params)
        .then((response) => {
          let data = response;
          console.log(data);
          if (data.code == 200) {
            if (data.data.basic.desc == null) {
              data.data.basic.desc = "";
            }

            this.info = data.data;
            this.editInfo = data.data;

            this.dialogDrugVisible = true;
            this.openType = type;
          } else {
            Message({
              message: data.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
          this.fullscreenLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoading = false;
        });
    },
    img() {
      this.flag = true;
    },
    // 不同层级指定不同refs
    under(pics, refPic = "pic") {
      // console.log(pics,refPic);
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    //清除筛选
    clearText() {
      this.searchParams = {};
      this.getList();
    },
    //清除高级搜索
    clearState() {
      this.searchParams.enable_status = "";
      this.searchParams.is_black = "";
      this.searchParams.drug_type = "";
      this.getList();
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      params.type = "1";
      getDrug(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    popoShow() {
      this.clearFilter = true;
      this.selected = true;
    },
    popoHide() {
      this.selected = false;
      this.clearFilter = false;
    },
    delDrug(item) {
      //    删除药品
      let params = {};
      params.id = item.drug_id;
      this.$confirm(`是否删除药品'${item.drug_name}'?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          delDrug(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg ? data.msg : data.message,
                });
              }
            })
            .catch((error) => {
              this.$message({
                type: "error",
                message: error.msg ? error.msg : error.message,
              });
            });
        })
        .catch(() => {});
    },
    enableStatus(item, status) {
      let params = {};
      params.id = item.id;
      params.enable_status = status;
      let statusName = "";
      if (status == 1) {
        statusName = "启用";
      } else {
        statusName = "禁用";
      }
      this.$confirm(`是否${statusName}药品'${item.drug_name}'?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: status == 1 ? "success" : "error",
      })
        .then(() => {
          enableDrug(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                this.$message({
                  type: "success",
                  message: statusName + "成功!",
                });
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: data.msg + "," + data.code,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    editDrug(type) {
      this.openType = type;
      this.dialogDrugVisible = true;
    },
    editSuccess() {
      this.dialogDrugVisible = false;
      this.getList("restPage");
    },
    filterCDNUrl(url) {
      if (!url) {
        return "";
      } else {
        if (url.indexOf("http") > -1) {
          return url;
        } else {
          return config.qiniuUrl + url;
        }
      }
    },

    beforeAvatarUpload(file) {
      this.qiniuData.key = file.name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
      if (file.name.indexOf(".") < 0) {
        this.$message.error("上传文件只能为 xls或者xlsx 格式!");
        return;
      }
      let typeArr = file.name.split(".");
      this.uploadFileName = file.name;
      let type = typeArr[typeArr.length - 1];

      const isJPG = type === "xls" || type === "xlsx";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传文件只能为 xls或者xlsx 格式!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return false;
      }

      this.uploadFile = file;
    },
    uploadExcelSuccess(res) {
      this.uploadFile = res.key;
    },
    getToken() {
      getQNToken()
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.qiniuData.token = data.data.token;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportFiles(file) {
      if (!this.uploadFile) {
        this.$message({
          type: "error",
          message: "请先上传Excel文件",
        });
        return;
      }

      console.log(this.uploadFile);

      const data = new FormData();
      const fileUps = file.file;
      data.append("file", fileUps);

      westDrugsImport(data)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            this.$message({
              type: "info",
              message: `批量导入成功${data.data.success}条，失败${data.data.fail}条`,
            });
            this.dialogVisible2 = false;
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep.el-table th.is-center{
//   background-color: rgb(249, 250, 252);
//   border-right-color:#EBEEF5;
//    color: rgb(102, 102, 102);
// }

.uploadExcel {
  font-size: 16px;

  a {
    display: block;
    width: 145px;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-right: none;
    color: #666;
    text-align: center;
    line-height: 40px;
    flex-shrink: 0;
  }

  .fileName {
    flex: 1;
    background: #f1f2f6;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
  }

  .uploadBtn {
    width: 100px;
    height: 40px;
    border: 1px solid #e4e4e4;
    border-left: none;
    color: #666;
    border-radius: 0;
    font-size: 16px;

    &:hover {
      background: transparent;
    }
  }
}

.title-filter {
  display: flex;
  height: 32px;
  line-height: 32px;

  .clearText {
    color: #3066fb;
    font-size: 13px;
  }

  .clearText:hover {
    cursor: pointer;
  }

  .filter:hover {
    cursor: pointer;
  }

  .advancedScreenings {
    background-color: white;
    width: 28px;
    height: 28px;
    line-height: 30px;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    margin-left: 10px;

    img {
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      width: 18px;
      height: 18px;
    }
  }

  .advancedScreening {
    background-color: rgb(24, 144, 255);
    width: 28px;
    height: 28px;
    line-height: 30px;
    border-radius: 4px;
    margin-left: 20px;

    img {
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      width: 18px;
      height: 18px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .new-doc {
    padding: 7px 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 28px;
  }

  .btnn {
    height: 32px;
    padding: 0 10px;
    margin-left: 20px;
    line-height: 32px;
  }
}

.advancedScreenings {
  background-color: white;
  width: 28px;
  height: 28px;
  line-height: 30px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  margin-left: 10px;

  img {
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    width: 18px;
    height: 18px;
  }
}

.advancedScreening {
  background-color: rgb(24, 144, 255);
  width: 28px;
  height: 28px;
  line-height: 30px;
  border-radius: 4px;
  margin-left: 20px;

  img {
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    width: 18px;
    height: 18px;
  }

  &:hover {
    cursor: pointer;
  }
}

::v-deep .el-drawer {
  bottom: 0 !important;
}

::v-deep .el-drawer__header {
  padding: 0;
  margin: 0;
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .el-drawer.rtl {
  overflow: auto;
  height: 91vh;
  bottom: 0;
  margin-top: 9vh;
  position: absolute;
}

::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}

::v-deep.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*打开时文字位置设置*/
::v-deep.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
::v-deep.switch .el-switch__label--right span {
  margin-left: -1px;
  // margin-right: 40px;
}

/*关闭时文字位置设置*/
::v-deep.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
::v-deep.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
::v-deep.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
::v-deep.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
</style>
