<template>
	<div class="gg-container" style="padding: 30px  " v-loading="fullscreenLoading">
		<el-tabs v-model="activeTab">
			<el-tab-pane label="基本信息" name="first">
				<div class="information-title">
					<div class="information-title-l">
						<div class="required">
						</div>
						<span>基础信息</span>
					</div>
					<el-dropdown trigger="click" v-if="type=='view'">
						<span class="el-dropdown-link">
							...
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="editInfos">编辑</el-dropdown-item>
							<el-dropdown-item @click.native="delDrug">删除</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<el-form :model="editInfo.basic" :rules="rules" ref="ruleForm" class="demo-ruleForm"
					:show-message="false">
					<div class="gg-grid">
						<el-row :gutter="20">
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="药品名称" prop="drug_name" v-if="type != 'view'">
											<el-input placeholder="请输入药品名称" v-model="editInfo.basic.drug_name"
												size="small" class="ipt">
											</el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>药品名称：</span>
											<span>{{info.basic.drug_name}}</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="是否处方药" prop="pres_not" v-if="type != 'view'">
											<el-select style="width: 112%;" v-model="editInfo.basic.pres_not" clearable
												placeholder="请选择" size="small">
												<el-option v-for="item in isOrNotOptions" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<div v-else class="infoText">
											<span>是否处方药：</span>
											<span v-if="info.pres_not"> 是 </span>
											<span v-else> 否 </span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="药品首字母" prop="first_char" v-if="type != 'view'">
											<el-input placeholder="请输入药品首字母" v-model="editInfo.basic.first_char"
												size="small" class="ipt">
											</el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>药品首字母：</span>
											<span>{{info.basic.first_char}}</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="药品剂型" prop="form_code" v-if="type != 'view'">
											<el-select style="width: 112%;" v-model="editInfo.basic.form_code" clearable
												placeholder="请选择" size="small">
												<el-option v-for="item in drugForms" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<div v-else class="infoText">
											<span>药品剂型：</span>
											<span>{{info.basic.form_name}}</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="药品规格" prop="specification" v-if="type != 'view'">
											<el-input placeholder="请输入药品规格" v-model="editInfo.basic.specification"
												size="small" class="ipt">
											</el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>药品规格：</span>
											<span>{{info.basic.specification}}</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="包装单位" prop="pack_unit" v-if="type != 'view'">
											<el-select style="width: 112%;" v-model="editInfo.basic.pack_unit" clearable
												placeholder="请选择" size="small">
												<el-option v-for="item in drugUnits" :key="item.value"
													:label="item.label" :value="item.label">
												</el-option>
											</el-select>
										</el-form-item>
										<div v-else class="infoText">
											<span>包装单位：</span>
											<span>{{info.basic.pack_unit}}</span>
										</div>
									</div>
								</div>
							</el-col>

							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="用量单位" prop="dose_unit" v-if="type != 'view'">
											<el-input v-model="editInfo.basic.dose_unit" placeholder="如:g、丸、袋、支、片等"
												class="ipt" size="small"></el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>用量单位：</span>
											<span>{{info.basic.dose_unit}}</span>
										</div>
									</div>
								</div>
							</el-col>

							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="生产厂家" prop="factory" v-if="type != 'view'">
											<el-input placeholder="请输入生产厂家" v-model="editInfo.basic.factory"
												size="small" class="ipt">
											</el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>生产厂家：</span>
											<span>{{info.basic.factory}}</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="药品类型" prop="cate_id" v-if="type != 'view'">
											<el-select style="width: 112%;box-sizing: border-box;"
												v-model="editInfo.basic.cate_id" clearable placeholder="请选择"
												size="small">
												<el-option v-for="item in drugCategoryOptions" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<div v-else class="infoText">
											<span>药品类型：</span>
											<span v-if="info.cate_id == 1">中药颗粒</span>
											<span v-else-if="info.cate_id == 2">西药</span>
											<span v-else-if="info.cate_id == 3">中成药</span>
											<span v-else>中药饮片</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="是否为黑名单药品" prop="is_black" v-if="type != 'view'">
											<el-select style="width: 112%;" v-model="editInfo.basic.is_black" clearable
												placeholder="请选择" size="small">
												<el-option v-for="item in isOrNotOptions" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<div v-else class="infoText">
											<span>是否为黑名单药品：</span>
											<span v-if="info.is_black"> 是 </span>
											<span v-else> 否 </span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="批文编号" prop="approval_number" v-if="type != 'view'">
											<el-input placeholder="请输入批文编号" v-model="editInfo.basic.approval_number"
												size="small" class="ipt">
											</el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>批文编号：</span>
											<span>{{info.basic.approval_number}}</span>
										</div>
									</div>
								</div>
							</el-col>

							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<div class="search-container-fn-input">
										<el-form-item label="建议零售价" prop="single_price" v-if="type != 'view'">
											<el-input placeholder="请输入建议零售价" v-model="editInfo.basic.single_price"
												size="small" class="ipt">
											</el-input>
										</el-form-item>
										<div v-else class="infoText">
											<span>建议零售价：</span>
											<span>{{info.basic.single_price}}</span>
										</div>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<el-form-item label="用药禁忌" prop="contra" v-if="type != 'view'">
										<el-input placeholder="请输入用药禁忌" v-model="editInfo.basic.contra" size="small"
											class="ipt"></el-input>
									</el-form-item>
									<div v-else class="infoText">
										<span>用药禁忌：</span>
										<span>{{info.basic.contra}}</span>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<div class="search-container">
									<el-form-item label="用药方法" prop="desc" v-if="type != 'view'">
										<el-input size="small" placeholder="请输入用药方法" v-model="editInfo.basic.desc"
											class="ipt"></el-input>
									</el-form-item>
									<div v-else class="infoText">
										<span>用药方法：</span>
										<span>{{info.basic.desc}}</span>
									</div>
								</div>
							</el-col>
							<el-col class="dashboard-container-col" :lg="12">
								<el-form-item label="药品备注" prop="remark" v-if="type != 'view'">
									<el-input type="textarea" placeholder="请输入药品备注" v-model="editInfo.basic.remark"
										style="width: 138%;"></el-input>
								</el-form-item>
								<div v-else class="infoText">
									<span>药品备注：</span>
									<span>{{info.basic.remark }}</span>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-form>
				<div style="display: flex;justify-content: center;margin-top: 30px;" v-if="type != 'view'">
					<el-button size="small" @click="cancelEdit">取消</el-button>
					<el-button type="primary" size="small" @click="saveForm('ruleForm')">保存</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="扩展信息" name="second">
				<div class="information-title">
					<div class="information-title-l">
						<div class="required">
						</div>
						<span>药品图片</span>
					</div>
					<el-dropdown trigger="click" v-if="types =='view'">
						<span class="el-dropdown-link">
							...
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="editInfoss">编辑</el-dropdown-item>
							<el-dropdown-item>删除</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="gg-grid">
					<el-form>
						<div class="bbox mt-2">
							<span style="font-size: 14px;color: #606266;display: inline-block;width: 70px;">主图：</span>
							<el-form-item prop="overview_url" class="bbox">
								<div class="elUpload">
									<div class="delss" v-if="types !== 'view' && editInfo.extra.overview_url"
										@click="editInfo.extra.overview_url = ''">
										<i class="el-icon-delete del"></i>
									</div>
									<div v-if="types !== 'view'" class="dels">
										<el-upload :disabled="types == 'view'" :action="actionPath" :data="qiniuData"
											:show-file-list="false" :on-success="uploadPhotoSuccess"
											:before-upload="beforeAvatarUpload">
											<img v-if="editInfo.extra.overview_url"
												:src="editInfo.extra.overview_url | filterCDNUrl" class="avatar" />
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
									</div>
									<div v-else>
										<el-image :src="info.extra.overview_url | filterCDNUrl"
											:preview-src-list="[filterCDNUrl(info.extra.overview_url)]" class="avatar">
										</el-image>
									</div>
								</div>
							</el-form-item>
						</div>
						<div class="bbox mt-2">
							<span style="font-size: 14px;color: #606266;display: inline-block;width: 70px;">详情图：</span>
							<el-form-item prop="desc_pic" class="bbox">
								<div class="">
									<div v-if="types !== 'view'">
										<div class="bbox">
											<div class="descimg mr-2" v-for="(img, index) in editInfo.extra.desc_pic"
												:key="index">
												<div class="delss" v-if="types !== 'view' " @click="delDescPic(index)">
													<i class="el-icon-delete del"></i>
												</div>
												<el-image class="img" :src="img ? img : filterCDNUrl"
													:preview-src-list="editInfo.extra.desc_pic">
												</el-image>
											</div>
											<div class="dels" v-if="editInfo.extra.desc_pic.length<5">
												<el-upload :disabled="types == 'view'" :action="actionPath"
													:data="qiniuData" :show-file-list="false"
													:on-success="uploadDescImgSuccess"
													:before-upload="beforeAvatarUpload">
													<i class="el-icon-plus avatar-uploader-icon"></i>
												</el-upload>
											</div>
										</div>
									</div>
									<div class="bbox" v-else>
										<div class="descimg mr-2 " v-for="(img, index) in info.extra.desc_pic"
											:key="index">
											<el-image class="img" :src="img ? img : filterCDNUrl"
												:preview-src-list="info.extra.desc_pic">
											</el-image>
										</div>
									</div>

								</div>
							</el-form-item>
						</div>
					</el-form>
				</div>
				<div class="gg-grid" style="width:90%">
					<div class="information-title">
						<div class="information-title-l">
							<div class="required">
							</div>
							<span>药品介绍</span>
						</div>
					</div>
					<el-row :gutter="20">
						<el-col class="dashboard-container-col" :span="24">
							<div v-if="types =='view'">
								<pre v-html="editInfo.extra.content" class="preContent"></pre>
							</div>
							<div v-else>
								<tinymce style="width:100%" v-model="editInfo.extra.content" :height="200" />
							</div>
						</el-col>
					</el-row>
				</div>
				<div style="display: flex;justify-content: center;margin-top: 30px;" v-if="types != 'view'">
					<el-button size="small" @click="cancelEdits">取消</el-button>
					<el-button type="primary" size="small" @click="saveForms">保存</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane label="三方药房" name="third">
				<div>
					<div class="title">
						<h3>药房药品价格</h3>
					</div>
					<!-- v-loading="listLoading" -->
					<el-table :data="pharmacys" border style="width: 100%">
						<el-table-column prop="pharmacy_no" align="center" width="120px" label="药房编号">
						</el-table-column>
						<el-table-column prop="pharmacy_name" label="药房名称">
						</el-table-column>
						<el-table-column prop="pharmacy_address" label="药房地址">
						</el-table-column>
						<el-table-column prop="sell_price" label="零售价">
							<template slot-scope="{ row }">
								<span> ￥{{ row.sell_price }} </span>
							</template>
						</el-table-column>
						<el-table-column prop="cost_price" label="成本价">
							<template slot-scope="{ row }">
								<span> ￥{{ row.cost_price }} </span>
							</template>
						</el-table-column>
						<el-table-column prop="stock_quantity" label="库存">
							<template slot-scope="{ row }">
								<span> {{ row.stock_quantity }}件 </span>
							</template>
						</el-table-column>
						<el-table-column prop="status_name" label="状态"> </el-table-column>
					</el-table>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import {
		editWDrug,
		wDrugDetail,
		delDrug,
	} from "@/api/drugs";
	import {
		editWDrugNew,
		editWDrugNews
	} from "@/api/drugs2.0";
	import {
		Message
	} from "element-ui";
	import {
		mapState
	} from "vuex";
	import {
		callMethod,
		getQNToken
	} from "@/api/basic";
	import config from "@/utils/config";
	import Tinymce from '@/components/Tinymce'

	export default {
		name: "wDetails",
		props: {
			id: {
				type: [Number, String],
			},
			type: {
				type: String,
				default: "view", // add  edit  view
			},
			types: {
				type: String,
				default: "view", // add  edit  view
			},
			info: {
				type: Object,
			},
			pharmacys: {
				type: Array,
			},
			editInfo: {
				type: Object,
			},
		},
		components: {
			Tinymce
		},
		data() {
			return {
				// info: {
				// 	overview_url: "",
				// 	desc_pic: [],
				// 	pharmacy_drugs: [],
				// },
				drugInfo: {},

				activeTab: "first",
				actionPath: "http://upload.qiniup.com",
				qiniuUrl: config.qiniuUrl,
				qiniuData: {
					token: "",
					key: "",
				},
				fullscreenLoading: false,
				isOrNotOptions: [{
						label: "是",
						value: true,
					},
					{
						label: "否",
						value: false,
					},
				],
				drugCategoryOptions: [
					// {
					// 	label: '中药颗粒',
					// 	value: '1'
					// },
					{
						label: "西药",
						value: '2'
					},
					{
						label: "中成药",
						value: '3'
					},
					// {
					// 	label: '中药饮片',
					// 	value: '4'
					// },
				],
				rules: {
					drug_name: [{
						required: true,
						message: "请输入药品名称",
						trigger: "blur"
					}, ],
					pres_not: [{
						required: true,
						message: "请选择是否处方药",
						trigger: "change"
					}, ],
					first_char: [{
						required: true,
						message: "请输入药品首字母",
						trigger: "blur"
					}, ],
					form_code: [{
						required: true,
						message: "请选择药品类型",
						trigger: "change"
					}, ],
					specification: [{
						required: true,
						message: "请输入药品规格",
						trigger: "blur"
					}, ],
					pack_unit: [{
						required: true,
						message: "请选择包装单位",
						trigger: "change"
					}, ],
					dose_unit: [{
						required: true,
						message: "请选择用量单位",
						trigger: "blur"
					}, ],
					factory: [{
						required: true,
						message: "请输入生产厂家",
						trigger: "blur"
					}, ],
					cate_id: [{
						required: true,
						message: "请选择药品类型",
						trigger: "change"
					}, ],
					is_black: [{
						required: true,
						message: "请选择是否为黑名单药品",
						trigger: "change",
					}, ],
					approval_number: [{
						required: true,
						message: "请输入药品批文编号",
						trigger: "blur"
					}, ],
					single_price: [{
						required: true,
						message: "请输入建议零售价",
						trigger: "blur"
					}, ],
					contra: [{
						required: true,
						message: "请输入用药禁忌",
						trigger: "blur"
					}, ],

					desc: [{
						required: true,
						message: "请输入使用方法",
						trigger: "blur"
					}],
					remark: [{
						required: true,
						message: "请输入备注",
						trigger: "blur"
					}],
				},
			};
		},
		created() {

			this.info.extra.content ? this.info.extra.content : this.info.extra.content = ''
			this.editInfo = this.clone(this.info)

			// if (this.id && this.type !== "add") {
			// 	this.getDetail();
			// }
			this.getToken();
		},
		mounted() {
			console.log(999999999999999)
			//剂型列表
			this.$store.dispatch('dictionary/getDicData', [{
				stateName: 'drugs',
				api: '/admin/drug/getDrugDicts'
			}]);
		},
		computed: {
			...mapState({
				drugUnits: (state) => state.dictionary.drugs.drug_unit,
				drugForms: (state) => state.dictionary.drugs.drug_dosage,
			}),
		},
		watch: {
			type(n, v) {
				console.log(7777777777777)
				this.editInfo = this.clone(this.info)
			},
		},
		methods: {
			//删除
			delDrug() {
				
				//    删除药品
				let params = {}
				params.id = this.id
				this.$confirm(`是否删除药品'${this.info.basic.drug_name}'?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error'
				}).then(() => {
					this.fullscreenLoading = true;
					delDrug(params).then(response => {
						let data = response
						if (data.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.loadList()
						} else {
							console.log(999999)
							this.$message({
								type: 'error',
								message: data.msg ? data.msg : data.message
							});
						}
						this.fullscreenLoading = false;
						this.$emit("editSuccess");
					}).catch(error => {
						console.log(888888)
						this.$message({
							type: 'error',
							message: error.msg ? error.msg : error.message
						});
					})
				}).catch(() => {});
			},
			//删除详情图
			delDescPic(index) {
				this.editInfo.extra.desc_pic.splice(index, 1)
			},
			//克隆对象
			clone(obj) {

				let temp = null;
				if (obj instanceof Array) {
					temp = obj.concat();
				} else if (obj instanceof Function) {
					temp = obj;
				} else {
					temp = new Object();
					for (let item in obj) {
						let val = obj[item];
						temp[item] = typeof val == 'object' ? this.clone(val) : val;
					}
				}
				return temp;
			},
			//编辑信息
			editInfos() {
				this.type = "edit"
			},
			//编辑扩展信息
			editInfoss() {
				this.types = "edit"
			},
			//取消编辑
			cancelEdit() {
				this.type = "view"
			},
			//取消扩展编辑
			cancelEdits() {
				this.types = "view"
			},
			getToken() {
				getQNToken()
					.then((response) => {
						let data = response;
						if (data.code == 200) {
							this.qiniuData.token = data.data.token;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},
			beforeAvatarUpload(file) {
				let name = new Date().getTime() + "_" + file.name //给file.name加上时间戳保证图片命名不重复
				this.qiniuData.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
				// debugger
				const isJPG = file.type === "image/jpeg" || file.type === "image/png";
				const isLt10M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error("上传头像图片只能是 JPG 格式!");
				}
				if (!isLt10M) {
					this.$message.error("上传头像图片大小不能超过 2MB!");
				}
				return isJPG && isLt10M;
			},
			uploadPhotoSuccess(res) {
				console.log(res)

				this.editInfo.extra.overview_url = res.key;
				console.log(this.editInfo.extra.overview_url)
			},

			uploadDescImgSuccess(res) {
				if (!Array.isArray(this.editInfo.extra.desc_pic)) {
					this.editInfo.extra.desc_pic = []
				}

				console.log(this.editInfo.extra.desc_pic, 99999)
				this.editInfo.extra.desc_pic.push('https://pic.hngegejk.cn//' + res.key);
				console.log(this.editInfo.extra.desc_pic)
			},
			//保存扩展信息
			saveForms() {

				let params = {
					...this.editInfo.extra
				}
				params.id = this.id
				console.log(params)
				editWDrugNews(params).then(response => {
					let data = response;
					if (data.code == 200) {
						this.$message({
							type: "success",
							message: "编辑成功！",
						});
					} else {
						this.$message({
							type: "error",
							message: data.message,
						});
					}
					this.fullscreenLoading = false;
					this.$emit("editSuccess");
				}).catch((error) => {
					this.$message({
						type: "error",
						message: error.response.data.message,
					});
					this.fullscreenLoading = false;
				});

				this.fullscreenLoading = true;
			},
			//保存基础信息
			saveForm(form) {
				this.$refs[form].validate((valid, object) => {
					if (!valid) {
						let item;
						for (let key in object) {
							item = object[key][0];
							break;
						}
						this.$message({
							type: "error",
							message: item.message,
						});
						return;
					} else {

						let params = {};
						// let curDrugForm = this.drugForms.find(v => v.value == this.editInfo.form_name)
						// console.log(curDrugForm,888)
						params = {
							...this.editInfo.basic
						};

						params.pres_not == false ? params.pres_not = '0' : params.pres_not = '1'
						params.is_black == false ? params.is_black = '0' : params.is_black = '1'
						// params.form_name = curDrugForm ? curDrugForm.form_name : ''
						params.id = this.id
						console.log(params)
						//编辑
						console.log(params, 777)
						editWDrugNew(params)
							.then((response) => {
								let data = response;
								if (data.code == 200) {
									this.$message({
										type: "success",
										message: "编辑成功！",
									});
								} else {
									this.$message({
										type: "error",
										message: data.message,
									});
								}
								this.fullscreenLoading = false;
								this.$emit("editSuccess");
							})
							.catch((error) => {
								this.$message({
									type: "error",
									message: error.response.data.message,
								});
								this.fullscreenLoading = false;
							});

						this.fullscreenLoading = true;
					}
				});
			},
			filterCDNUrl(url) {
				if (!url) {
					return "";
				} else {
					if (url.indexOf("http") > -1) {
						return url;
					} else {
						return config.qiniuUrl + url;
					}
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.gg-container {
		margin: 0 !important;
	}

	.preContent {
		margin: 0;
		overflow: auto;
		white-space: pre-wrap;
		border: 1px solid #ececec;

		p {
			margin: 0;
			padding: 0;
		}
	}

	::v-deep .el-tab-pane {
		height: 780px;
		overflow: auto;
	}

	// .gg-grid {
	// 	padding: 15px 0;
	// 	border-bottom: 0px solid #f0f0f0 !important;
	// }
	.gg-grid {
		border-bottom: 0 !important;
	}

	.ipt {
		width: 120%;
		box-sizing: border-box;
	}

	.el-form-item {

		margin-bottom: 0;
		align-items: center;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.gg-grid {
		padding: 0 !important;
	}

	.el-dropdown-link {
		display: inline-block;
		width: 30px;
		height: 20px;
		border: 1px solid #dfe4ed;
		text-align: center;
		line-height: 10px;
	}

	.search-container {
		margin-bottom: 0 !important;
	}

	.avatar-uploader-icon {
		background-color: #F6f6f6;
		font-size: 28px;
		color: #8c939d;
		width: 120px !important;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.information-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 16px 0 6px 0;

		span {
			display: inline-block;
			margin-left: 10px;
		}
	}

	.infoText {
		height: 40px;
		line-height: 40px;
		font-size: 13px;

		span:nth-child(1) {
			color: #606266;
			font-weight: bold;

		}
	}

	.required {
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		position: absolute;
		top: 20px;

	}

	.elUpload {
		text-align: center;
		width: 120px;
		height: 120px;
		position: relative;

		// .avatar-uploader {
		// 	border: 1px dotted #666;
		// }

		.el-upload {
			width: 100%;
			height: 100%;

			.avatar-uploader-icon {
				width: 100%;
				height: 100%;
			}
		}

		.avatar {
			max-width: 100%;
			height: 120px;

		}

		// .acatar:before{
		// 	content: '';
		// 	width: 120px;
		// 	height: 120px;
		// 	background:#000;
		// 	opacity:0.7;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// }

	}

	.del {
		position: absolute;
		right: 45px;
		top: 45px;
		color: #fff;
		font-size: 30px;
		cursor: pointer;
	}

	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}

	.descimg {
		width: 120px;
		height: 120px;
		display: block;

		position: relative;
		overflow: hidden;



		.img {
			width: 120px;
			height: 120px;
		}
	}

	.title {
		border-left: 5px solid #1890ff;
		padding: 0 10px;
		border-radius: 2px;
	}

	.el-table thead th {
		background-color: rgb(249, 250, 252) !important;
		color: rgb(102, 102, 102) !important;
	}

	.dels {
		border: 1px dashed #4ebbfc;
		box-sizing: border-box;
		width: 122px;
		height: 122px;
	}

	.delss {
		opacity: 0.5;
		background-color: #000;
		width: 122px;
		height: 122px;
		position: absolute;
		z-index: 100;

		i {
			z-index: 101;
		}
	}
</style>
